<template>
  <div class="material-video-box">
    <div class="flex-between mgn-b10 pdg-lr20">
      <div class="flex-start">
        <div class="material-video-search-box">
          <el-input
            v-model="searchData.searchKey"
            placeholder="搜索内容标题/ID/创建者"
            suffix-icon="el-icon-search"
            @change="onSearchChange"
          />
        </div>
        <div class="pdg-lr10 select-item">
          <el-select
            v-model="searchData.isQueryAll"
            placeholder="素材来源"
            style="width: 110px;"
            @change="onSelectChange"
          >
            <el-option
              v-for="item in orgSource"
              :key="item.isQueryAll"
              :label="item.title"
              :value="item.isQueryAll"
            />
          </el-select>
        </div>
        <div class="pdg-lr10 select-item">
          <el-select
            v-model="searchData.tagId"
            style="width: 110px;"
            placeholder="所有产品"
            class="reset-item"
            clearable
            @change="onSelectChange"
          >
            <el-option
              v-for="product in productList"
              :key="product.tagId"
              :label="product.tagName"
              :value="product.tagId"
            />
          </el-select>
        </div>
        <div v-if="searchData.isQueryAll === false" class="pdg-lr10 select-item">
          <el-select
            v-model="searchData.categoryId"
            style="width: 110px;"
            placeholder="所有分类"
            @change="onSelectChange"
          >
            <el-option
              v-for="item in videoClassifyList"
              :key="item.categoryId"
              :label="item.title"
              :value="item.categoryId"
            />
          </el-select>
        </div>
      </div>
      <span class="text-primary-link" @click="onCreate">本地上传</span>
    </div>
    <el-table :data="tableDataList" border style="width: 100%;">
      <el-table-column width="55" align="center">
        <template slot-scope="scope">
          <el-checkbox
            :value="isSelect(scope.row.videoId)"
            @change="onCheckedChange(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="videoId"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="title"
        label="视频名称"
        align="center"
        min-width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scoped">
          <el-button type="text" @click="onOpenDialog(scoped.row)">
            {{
              scoped.row.title
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="duration"
        label="视频时长(分钟)"
        align="center"
      >
        <template slot-scope="scope">
          {{ parseFloat(((scope.row.duration || 0) / 60).toFixed(1)) }}
        </template>
      </el-table-column>
      <!-- 产品标签 -->
      <el-table-column prop="productTag" label="产品标签" align="center">
        <template slot-scope="scope">
          <span v-for="(item, index) of scope.row.tagInfos" :key="index">
            {{ item.tagName }}
          </span>
        </template>
      </el-table-column>
      <!-- 上传时间 -->
      <el-table-column
        prop="dateTime"
        label="上传时间"
        align="center"
      />
    </el-table>
    <el-pagination
      class="sorter-box"
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="total"
      background
      hide-on-single-page
      layout="total, prev, pager, next"
      small
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MaterialVideoChild',
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 100
    },
    initCheckedList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      // -------------------- 搜索、页数 -----------------
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 5,
        categoryId: '',
        searchKey: ''
      },
      // ---------------------- select -----------------
      videoClassifyList: [],
      // -------------------- submit ----------------
      btnLoading: false,
      // ----------------------- vedio table -----------------
      tableDataList: [],
      selectedList: [],
      orgSource: [
        { isQueryAll: true, title: '所有素材' },
        { isQueryAll: false, title: '我的素材' }
      ],
      productList: [],
      showVideoVewer: false,
      currentSrc: {}
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.initPage()
      }
    },
    initCheckedList(val) {
      if (val) {
        this.selectedList = val
      }
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    ...mapActions(['onGetTrainVideoList', 'onGetCategoryList']),
    onClose() {
      this.$emit('onClose', false)
    },
    onCreate() {
      const routeUrl = this.$router.resolve('/material/list?activeName=video')
      window.open(routeUrl.href, '_blank')
    },
    getValue() {
      return this.selectedList
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onVideoList()
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onVideoList()
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 5,
        categoryId: '',
        searchKey: ''
      }
      this.selectedList = this.initCheckedList
      this.tableDataList = []
      this.onVideoList()
      this.onGetVideoCategoryList()
      this.getTagGroup().then(res => {
        const row = res.find(item => item.groupName.includes('产品'))
        this.getProdectList(row.id)
      })
    },
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup)
      if (data.length) {
        this.tagGroup = data
      }
      return data
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId
      }
      const { data } = await this.$axios.get(this.$API.tagList, { params })
      if (data.length) {
        this.productList = data
      }
    },
    onGetVideoCategoryList() {
      this.onGetCategoryList({ categoryType: 'video' }).then(res => {
        this.videoClassifyList = res.data || []
      })
    },
    onVideoList() {
      this.onGetTrainVideoList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0
          this.tableDataList = data || []
        }
      )
    },
    isSelect(currentId) {
      return (
        [...this.selectedList]?.map(v => v.videoId).indexOf(currentId) > -1
      )
    },
    onCheckedChange(item) {
      if (this.isSelect(item.videoId)) {
        this.selectedList = [...this.selectedList].filter(
          v => v.videoId !== item.videoId
        )
      } else {
        if (this.limit === 1) {
          this.selectedList = []
        }
        this.selectedList = [...this.selectedList, item]
      }
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onVideoList()
    }
  }
}
</script>

<style>
.material-video-search-box .el-input input {
  width: 250px;
  border-radius: 20px;
}
</style>
<style lang='scss' scoped>
.material-video-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  .video-list {
    flex: 1;
    overflow-y: auto;
    .el-checkbox {
      width: 100%;
      margin-right: unset;
      display: flex;
      align-items: center;
    }
  }
}
.video-cover {
  width: 107px;
  height: 60px;
  background: #d8d8d8;
  margin-right: 20px;
}
.bgf6f7fb {
  background: #f6f7fb;
}
.sorter-box {
  padding: 10px;
  text-align: right;
  background: #fff;
}
</style>
