<template>
  <div class="add-question-pop-box">
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div slot="title">添加文献</div>
      <div class="flex-between content">
        <div class="item-box left-box content-box">
          <el-row type="flex" justify="space-between" class="pdg-tb10">
            <div class="col272e40 font-bold">
              <span>文献</span>
              <el-tooltip effect="dark" content="刷新文献" placement="bottom">
                <span class="mgn-l10 title" @click="onGetArticleList">
                  <svg-icon name="el-icon-refresh" />
                </span>
              </el-tooltip>
            </div>
            <span class="text-primary-link" @click="onCreate">新建文献</span>
          </el-row>
          <el-row type="flex" justify="end" class="mgn-b10">
            <div class="material-question-search-box">
              <el-input
                placeholder="搜索内容标题/ID/创建者"
                suffix-icon="el-icon-search"
                @change="onSearchChange"
                v-model="searchData.searchKey"
              >
              </el-input>
            </div>
            <div class="pdg-lr10 select-item">
              <el-select
                v-model="searchData.isQueryAll"
                @change="onSelectChange"
                placeholder="素材来源"
              >
                <el-option
                  v-for="item in orgSource"
                  :key="item.isQueryAll"
                  :label="item.title"
                  :value="item.isQueryAll"
                >
                </el-option>
              </el-select>
            </div>
            <div class="pdg-lr10 select-item">
              <el-select
                v-model="searchData.tagId"
                style="width: 110px"
                placeholder="所有产品"
                class="reset-item"
                clearable
                @change="onSelectChange"
              >
                <el-option
                  v-for="product in productList"
                  :key="product.tagId"
                  :label="product.tagName"
                  :value="product.tagId"
                />
              </el-select>
            </div>
            <div class="pdg-lr10 select-item"  v-if="searchData.isQueryAll === false">
              <el-select
                v-model="searchData.categoryId"
                @change="onSelectChange"
                placeholder="所有分类"
                clearable
              >
                <el-option
                  v-for="item in examClassifyList"
                  :key="item.categoryId"
                  :label="item.title"
                  :value="item.categoryId"
                >
                </el-option>
              </el-select>
            </div>
          </el-row>

          <div class="content-box" style="flex: 1">
            <el-table
              :data="sourceList"
              style="width: 100%"
              height="300"
              border
            >
              <el-table-column
                prop="knowledgeId"
                label="ID"
                width="100"
                show-overflow-tooltip
              />
              <el-table-column prop="title" label="文献标题">
                <template slot-scope="scope">
                  <span class="title" @click="onCheck(scope.row)">{{
                    scope.row.title
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="knowledgeType"
                label="文献类型"
                width="70"
              />
              <el-table-column
                prop="title"
                label="产品标签"
                width="70"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    v-for="(item, index) of scope.row.tagInfos"
                    :key="index"
                    >{{ item.tagName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="操作" width="70" center>
                <template slot-scope="scope">
                  <span
                    v-if="isSelect(scope.row.knowledgeId)"
                    style="color: #c8c9ce"
                    >已选择</span
                  >
                  <div class="cursor" @click="onAdd(scope.row)" v-else>
                    <i class="el-icon-circle-plus-outline opt-label"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="item-box right-box content-box">
          <div class="col272e40 font-bold pdg-t30">当前选择文献：</div>
          <div class="col7784a1 mgn-b10">请从左侧列表选择添加</div>
          <div class="content-box check-box" style="flex: 1">
            <el-table
              :data="targetList"
              style="width: 100%"
              height="200"
              border
            >
              <el-table-column
                prop="knowledgeId"
                label="ID"
                show-overflow-tooltip
              />
              <el-table-column label="操作" width="70" center>
                <template slot-scope="scope">
                  <div class="cursor" @click="onDel(scope.row.knowledgeId)">
                    <i class="el-icon-remove-outline opt-label"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <el-pagination
        class="sorter-box"
        @current-change="onCurrentChange"
        :current-page="searchData.pageNo"
        :page-size="searchData.pageSize"
        :total="total"
        :small="true"
        :pager-count="pagerCount"
        background
        layout="total, prev, pager, next, jumper"
      ></el-pagination>
      <div slot="footer" class="flex-center mgn-t20">
        <el-button plain @click="onClose">取消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddArticleModal",
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: false,
    },
    selectList: {
      type: Array,
      default() {
        return [];
      },
    },
    knowledgeType: {
      type: null,
      default: "",
    },
  },
  components: {},
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.initPage();
      }
    },
  },
  computed: {
    isSelect() {
      return (currentId) => {
        let targetSelect = [...this.targetList].map((v) => v.knowledgeId);
        return targetSelect.indexOf(currentId) > -1;
      };
    },
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
      // -------------------- 搜索、页数 -----------------
      pagerCount: 5,
      small: true,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        type: 0,
        searchKey: "",
        categoryId: "",
        isQueryAll: true,
        tagId: "",
      },
      // ---------------------- select -----------------
      examClassifyList: [],
      // --------------------- questionList ----------------------
      sourceList: [],
      targetList: [],
      orgSource: [
        { isQueryAll: true, title: "所有素材" },
        { isQueryAll: false, title: "我的素材" },
      ],
      productList: [],
    };
  },
  methods: {
    ...mapActions(["getArticleList", "onGetCategoryList"]),
    onClose() {
      this.$emit("onClose", false);
    },
    onCreate() {
      const routeUrl = this.$router.resolve(
        "/material/knowledge/create?from=addArticleModal"
      );
      window.open(routeUrl.href, "_blank");
    },
    onSubmit() {
      this.$emit("onSubmit", this.targetList);
      this.onClose();
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 10,
        searchKey: "",
        categoryId: "",
        knowledgeType: this.knowledgeType || "",
        isQueryAll: true,
        tagId: "",
      };
      this.sourceList = [];
      this.targetList = this.selectList;
      this.onGetArticleList();
      this.onGetExamCategoryList();
      this.getTagGroup().then((res) => {
        const row = res.find((item) => item.groupName.includes("产品"));
        this.getProdectList(row.id);
      });
    },
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup);
      if (data.length) {
        this.tagGroup = data;
      }
      return data;
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId,
      };
      const { data } = await this.$axios.get(this.$API.tagList, { params });
      if (data.length) {
        this.productList = data;
      }
    },
    onGetArticleList() {
      this.getArticleList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0;
          this.sourceList = data || [];
        }
      );
    },
    // 查看
    onCheck(row) {
      if (row.knowledgeType === "WORD") {
        const routeUrl = this.$router.resolve({
          path: `/material/article/preview/${row.knowledgeId}`,
        });
        window.open(routeUrl.href, "_blank");
      } else {
        window.open(row.pdfUrl, "_blank");
      }
    },
    onSearchChange() {
      this.$set(this.searchData, "pageNo", 1);
      this.onGetArticleList();
    },
    onSelectChange() {
      this.$set(this.searchData, "pageNo", 1);
      this.onGetArticleList();
    },
    onGetExamCategoryList() {
      this.onGetCategoryList({ categoryType: "knowledge" }).then((res) => {
        this.examClassifyList = res.data || [];
      });
    },
    onCurrentChange(options) {
      this.$set(this.searchData, "pageNo", options);
      this.onGetArticleList();
    },
    onAdd(item) {
      this.targetList = [...this.targetList, item];
    },
    onDel(currentId) {
      this.targetList = [...this.targetList].filter(
        (v) => v.knowledgeId !== currentId
      );
    },
  },
};
</script>

<style>
.add-question-pop-box .el-dialog {
  width: 1000px;
}

.add-question-pop-box .el-dialog__body {
  padding: 0px;
}
</style>
<style lang='scss' scoped>
.content {
  @media screen and (max-width: 768px) {
    height: 300px;
    overflow: scroll;
  }
}

.material-question-search-box ::v-deep {
  flex: 1;
  padding-right: 10px;

  .el-input input {
    border-radius: 20px;
  }
}

.opt-label {
  color: #3d61e3;
  font-size: 20px;
}

.item-box {
  // width: 490px;
  height: 500px;
  @media screen and (max-width: 768px) {
    height: 300px;
    overflow: scroll;
  }
}

.left-box {
  width: 80%;
  margin-right: 10px;
}
.right-box {
  width: 20%;
}

.content-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.check-box {
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.check-box::-webkit-scrollbar {
  width: 0 !important;
}

.sorter-box {
  padding: 10px;
  text-align: center;
  background: #ffffff;
}
.select-item {
  width: 120px;
}
.title {
  cursor: pointer;
  color: #3d61e3;
}
</style>
