<template>
  <div class="packet-answer">
    <el-dialog
      title="查看详情"
      :visible.sync="dialogVisible"
      destroy-on-close
      width="40%"
    >
      <div v-for="item in examList" :key="item.examId" class="item">
        <p class="title">
          {{ item.examIndex }}. {{ item.title | titleFilter }}
          <span> &nbsp; ({{ item.examType | examTypeFilter }})</span>
        </p>
        <ul v-if="item.examType === 0 || item.examType === 1">
          <li v-for="(itemRecords,index) in item.records" :key="itemRecords.examAnswerId" class="answer">
            <span class="answer-title">
              {{ index |filter }}. {{ itemRecords.answer }}{{ itemRecords.isRight?'（推荐/正确选项）':'' }}
            </span>
            <span class="choose-num">{{ itemRecords.pickNum }}人选择</span>
          </li>
        </ul>
      </div>
      <div slot="footer" style="text-align: center;">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'PacketAnswer',
  components: {},
  filters: {
    filter(i) {
      const array = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
      return array[i]
    },
    titleFilter(title) {
      const titleNew = title?.replace(/<filter><\/filter>/g, '__ ')?.replace(/,/g, '，')
      return titleNew
    },
    examTypeFilter(type) {
      const obj = {
        0: '单选',
        1: '多选',
        2: '填空',
        3: '问答',
        5: '排序',
        6: '量表'
      }
      return obj[type]
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    examList: {
      type: Array
    }
  },
  data() {
    return {}
  },
  computed: {
    dialogVisible: {
      get: function() {
        return this.value
      },
      set: function(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
// scss
.packet-answer {
  ::v-deep.el-dialog__body {
    padding: 20px;
    max-height: 500px;
    overflow: scroll;
  }
  .title {
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
    font-weight: 600;
    color: #0f0f0f;
    line-height: 12px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .item:nth-child(1) {
    .title {
      margin-top: 0;
    }
  }
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .choose-num {
      font-size: 12px;
      font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      font-weight: 400;
      color: #969696;
      line-height: 32px;
    }
  }
}
</style>
