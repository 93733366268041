<template>
  <div>
    <template v-for="item in schemeList">
      <BaseIndexVue
        :key="item.id"
        :item="item"
        :root="schemeList[0]"
      />
    </template>
    <video-viewer v-model="showVideoVewer" :video-url="currentSrc" />
  </div>
</template>

<script>
import VideoViewer from '@/views/material/components/VideoViewer.vue'

import {runInitFunSingleton, setRootItem} from '@/baseComponents/commonBase/baseUtils'
import { mapActions } from 'vuex'

export default {
  name: 'CommonList',
  components: {
    VideoViewer
  },
  data() {
    return {
      showVideoVewer: false,
      currentSrc: {},

      // schemeList
      schemeList: [
        {
          that: 'this',
          baseVue: 'BasePageMain',
          title: '活动管理 - 公共资源库',
          back: true,
          data: {
            isVisible: false,
            visibleItem: {},
            academicType: 'academic-program',
            isLoading: false,
            initSearchObj: {
              source: '',
              sceneType: '',
              productTagGroup: '',
              spreadDepartment: '',
              title: '',
              pageNo: 1,
              pageSize: 10,
              tagAndGroupIds: [],
              isCreator: '',
              sceneTypeCode: '',
              resourceType: null
            },
            reqObj: {
              'academic-program': {
                apiUrl: 'scenePublicResourceLibProjectList',
                data: {
                  pageNo: 1,
                  pageSize: 10,
                  projectName: '',
                  strategyIds: [],
                  productIds: [],
                  projectStatus: null,
                  projectTargetType: null,
                  isMyself: true
                },
                cancelObj: {}
              },
              'project': {
                apiUrl: 'scenePublicResourceLibActivityList',
                data: {
                  title: '',
                  pageNo: 1,
                  pageSize: 10,
                  tagAndGroupIds: [],
                  isCreator: '',
                  sceneTypeCode: '',
                  questionnaireSubType: ''
                },
                cancelObj: {
                  apiUrl: 'cancelSceneShare',
                  method: 'get',
                  data: {
                    sceneId: '',
                    sceneType: '',
                    isShareOrCancel: false
                  }
                }
              },
              'material': {
                apiUrl: 'scenePublicResourceLibLiteratureList',
                data: {
                  title: '',
                  pageNo: 1,
                  pageSize: 10,
                  tagAndGroupIds: [],
                  isCreator: '',
                  resourceType: null
                },
                cancelObj: {
                  apiUrl: 'cancelMaterialShare',
                  method: 'post',
                  data: {
                    resourceIds: val => {
                      const ids = []
                      ids.push(val)
                      return ids
                    },
                    resourceType: null,
                    isShareOrCancel: false
                  },
                  dataMaps: {
                    resourceIds: 'resourceId'
                  }
                }
              }
            },
            tagItemList: {
              PRODUCT_TAG_GROUP: [],
              SPREAD_DEPARTMENT_GROUP: []
            },
            PRODUCT_TAG_GROUP_ID: '',
            SPREAD_DEPARTMENT_GROUP_ID: '',
            tableData: [],
            total: 0,
            strategyList: []
          },
          init: function({root, runInitFun}) {
            const { onGetTagGroup, getTagList, getTableDataList, getStrategyList } = root.initFun
            runInitFun(onGetTagGroup).then(res => {
              res.forEach(item => {
                runInitFun(getTagList, {tagGroupId: item.id, groupCode: item.groupCode})
              })
            })
            runInitFun(getTableDataList)
            runInitFun(getStrategyList)
          },
          initFun: {
            onGetTagGroup: function(options, {root, setRootItem}) {
              return this.$axios.get(this.$API.getTagGroup).then(res => {
                const {data = []} = res
                const groupList = []
                const list = []
                data.forEach(v => {
                  if (v.groupCode === 'PRODUCT_TAG_GROUP') {
                    list[0] = v
                    groupList[0] = {
                      groupId: v.id,
                      tagIds: []
                    }
                  } else if (v.groupCode === 'SPREAD_DEPARTMENT_GROUP') {
                    list[1] = v
                    groupList[1] = {
                      groupId: v.id,
                      tagIds: []
                    }
                  }
                })
                setRootItem(root, 'data.initSearchObj.tagAndGroupIds', groupList)
                return list
              }, rea => this.$message.error(rea.message))
            },
            // 请求获取分组标签列表
            getTagList: function({tagGroupId, groupCode}, {setRootItem, root}) {
              setRootItem(root, `data.${groupCode}_ID`, tagGroupId)
              const params = {tagGroupId}
              this.$axios.get(this.$API.tagList, { params }).then(res => {
                const {data} = res
                const list = []
                data.map(item => {
                  list.push({label: item.tagName, value: item.tagId})
                })
                setRootItem(root.data.tagItemList, groupCode, list)
              }, rea => this.$message.error(rea.message))
            },
            // 请求table列表
            getTableDataList: function(options, {root, setRootItem}) {
              const reqItemObj = root.data.reqObj[root.data.academicType]
              const creatorObj = {
                self: true,
                other: false
              }
              const isCreator = creatorObj[root.data.initSearchObj.source]
              setRootItem(root.data.initSearchObj, 'isCreator', isCreator)
              const type = {
                meeting: 1,
                questionnaire: 4,
                survey: 4,
                live: 5,
                webinar: 6,
                video: 0,
                knowledge: 2
              }
              const sceneType = type[root.data.initSearchObj.sceneType]
              if (sceneType === 4) {
                if (root.data.initSearchObj.sceneType === 'survey') {
                  setRootItem(root.data.initSearchObj, 'questionnaireSubType', 1)
                } else {
                  setRootItem(root.data.initSearchObj, 'questionnaireSubType', 2)
                }
              }
              setRootItem(root.data.initSearchObj, 'sceneTypeCode', sceneType,)
              setRootItem(root.data.initSearchObj, 'resourceType', sceneType,)
              const params = {}
              Object.keys(reqItemObj.data).forEach(key => {
                params[key] = root.data.initSearchObj[key]
              })
              const method = reqItemObj.method || 'post'
              this.$axios[method](this.$API[reqItemObj.apiUrl], method === 'get' ? {params} : params).then(res => {
                const {data, totalCount} = res.data || {}
                setRootItem(root, 'data.isLoading', false)
                setRootItem(root, 'data.tableData', data)
                setRootItem(root, 'data.total', totalCount)
              }, rea => {
                setRootItem(root, 'data.isLoading', false)
                this.$message.error(rea.message)
              })
            },
            changeTableList: function(options, {root, setRootItem, runInitFun}) {
              setRootItem(root.data, 'tableData', [])
              setRootItem(root.data, 'initSearchObj', {
                source: '',
                sceneType: '',
                productTagGroup: '',
                spreadDepartment: '',
                title: '',
                pageNo: 1,
                pageSize: root.data.initSearchObj.pageSize,
                tagAndGroupIds: root.data.initSearchObj.tagAndGroupIds.map(it => {
                  it.tagIds = []
                  return it
                }),
                isCreator: '',
                sceneTypeCode: '',
                resourceType: null
              })
              const {getTableDataList} = root.initFun
              runInitFun(getTableDataList)
            },
            getStrategyList(options, {root, setRootItem }) {
              this.$axios.get(this.$API.strategyList, {
                params: {
                  status: 1,
                  pageNo: 1,
                  pageSize: 999999
                }
              }).then(res => {
                const {data} = res.data
                setRootItem(root.data, 'strategyList', data.map(item => {
                  return {
                    label: item.strategyName,
                    value: item.strategyId
                  }
                }))
              })
            }
          },
          childSchemeList: [
            {
              baseVue: 'BaseRow',
              colCount: 1,
              style: {

              },
              colStyle: [
                {
                  position: 'relative'
                }
              ],
              childSchemeList: [
                {
                  id: this.$nanoid(),
                  columnIndex: 1,
                  baseVue: 'BaseTabs',
                  value: 'getRootItem(root,item.valueKey)',
                  valueKey: 'data.academicType',
                  disabled: 'getRootItem(root,item.disabledKey)',
                  disabledKey: 'data.isLoading',
                  tabList: [
                    {
                      label: '学术项目',
                      name: 'academic-program'
                    },
                    {
                      label: '学术活动',
                      name: 'project'
                    }
                    // 素材重复性项目去掉学术资料
                    // {
                    //   label: '学术资料',
                    //   name: 'material'
                    // }
                  ],
                  methods: {
                    // eslint-disable-next-line no-unused-vars
                    tabClick: function(options, {root, runInitFun, setRootItem}) {
                      if (root.data.isLoading) return
                      setRootItem(root, 'data.isLoading', true)
                      setRootItem(root, 'data.academicType', options.value)
                      const {changeTableList} = root.initFun
                      runInitFun(changeTableList, options)
                    }
                  }
                },
                {
                  baseVue: 'BaseButton',
                  isShow: function({root}) {
                    return root.data.academicType === 'material'
                  },
                  columnIndex: 1,
                  style: {
                    position: 'absolute',
                    top: '5px',
                    right: '30px'
                  },
                  type: 'text',
                  text: '查看数据',
                  methods: {
                    click: function(options) {
                      console.log(options, '===== 查看数据 =======')
                      this.$router.push({name: 'CommonMaterial'})
                    }
                  }
                }
              ]
            },
            {
              baseVue: 'BaseRow',
              colCount: 2,
              style: {
                paddingTop: '10px'
              },
              colStyle: [
                {
                  display: 'flex'
                }
              ],
              childSchemeList: [
                {
                  columnIndex: 1,
                  baseVue: 'BaseInput',
                  placeholder: '搜索名称',
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.projectName',
                  clearable: true,
                  style: {
                    width: '160px'
                  },
                  methods: {
                    input: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.initSearchObj, 'projectName', options.value)
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                {
                  columnIndex: 1,
                  baseVue: 'BaseSelect',
                  placeholder: '项目状态',
                  clearable: true,
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  isShow({root}) {
                    return root.data.academicType === 'academic-program'
                  },
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.projectStatus',
                  selectList: [
                    {
                      label: '进行中',
                      value: '1'
                    },
                    {
                      label: '已结束',
                      value: '2'
                    }
                  ],
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.initSearchObj, 'projectStatus', options.value)
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                {
                  columnIndex: 1,
                  baseVue: 'BaseSelect',
                  placeholder: '项目来源',
                  clearable: true,
                  isShow({root}) {
                    return root.data.academicType === 'academic-program'
                  },
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.isMyself',
                  selectList: [
                    { label: '我的项目', value: 1 },
                    { label: '其他组织分享的项目', value: 2 }
                  ],
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.initSearchObj, 'isMyself', options.value === 1 ? true : options.value === 2 ? false : null)
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                {
                  columnIndex: 1,
                  baseVue: 'BaseSelect',
                  placeholder: '活动来源',
                  isShow({root}) {
                    return root.data.academicType !== 'academic-program'
                  },
                  clearable: true,
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.source',
                  selectList: [
                    { label: '我创建的', value: 'self' },
                    { label: '其他组织分享', value: 'other' }
                  ],
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.initSearchObj, 'source', options.value)
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                {
                  columnIndex: 1,
                  baseVue: 'BaseSelect',
                  isShow({root}) {
                    return root.data.academicType !== 'academic-program'
                  },
                  placeholder: function({root}) {
                    return root.data.academicType === 'project' ? '活动类型' : '素材类型'
                  },
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  clearable: true,
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.sceneType',
                  selectList: function({root}) {
                    const obj = {
                      project: [
                        { label: '科会', value: 'meeting' },
                        { label: '调研', value: 'survey' },
                        { label: '病例', value: 'questionnaire' },
                        { label: '直播', value: 'live' },
                        { label: '会议', value: 'webinar' }
                      ],
                      material: [
                        { label: '视频', value: 'video' },
                        { label: '文献', value: 'knowledge' }
                      ]
                    }
                    return obj[root.data.academicType]
                  },
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.initSearchObj, 'sceneType', options.value,)
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                {
                  columnIndex: 1,
                  this: this,
                  baseVue: 'BaseSelect',
                  placeholder: '产品标签',
                  layoutRight: true,
                  class: 'select-complex collapse-tags',
                  selectClass: 'select',
                  rightClass: 'right-btn',
                  style: {
                    width: '150px',
                    marginLeft: '10px'
                  },
                  multiple({root}) {
                    return root.data.academicType === 'academic-program'
                  },
                  collapseTags({root}) {
                    return root.data.academicType === 'academic-program'
                  },
                  clearable: true,
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.productTagGroup',
                  selectList: 'getRootItem(root,item.selectListKey)',
                  selectListKey: 'data.tagItemList.PRODUCT_TAG_GROUP',
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      if (root.data.academicType === 'academic-program') {
                        setRootItem(root.data.initSearchObj, 'productIds', options.value)
                      } else {
                        setRootItem(root.data.initSearchObj, 'productTagGroup', options.value)
                        setRootItem(root.data.initSearchObj.tagAndGroupIds[0], 'tagIds', options.value ? [options.value] : [])
                      }
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    },
                    click: function(options, {root}) {
                      this.$router.push({name: 'ProductLabel', query: {activeTabName: root.data.PRODUCT_TAG_GROUP_ID}})
                    }
                  }
                },
                {
                  columnIndex: 1,
                  this: this,
                  baseVue: 'BaseSelect',
                  placeholder: '所有策略',
                  isShow({root}) {
                    return root.data.academicType === 'academic-program'
                  },
                  class: 'select-complex collapse-tags',
                  selectClass: 'select',
                  multiple: true,
                  collapseTags: true,
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.strategyIds',
                  selectList: 'getRootItem(root,item.selectListKey)',
                  selectListKey: 'data.strategyList',
                  style: {
                    width: '150px',
                    marginLeft: '10px'
                  },
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.initSearchObj, 'strategyIds', options.value)
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                {
                  columnIndex: 1,
                  this: this,
                  baseVue: 'BaseSelect',
                  isShow({root}) {
                    return root.data.academicType === 'academic-program'
                  },
                  placeholder: '所有类型',
                  class: 'select-complex',
                  selectClass: 'select',
                  clearable: true,
                  selectList: [
                    { label: '观念覆盖', value: 1 },
                    { label: '播客培养', value: 2 }
                  ],
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.projectTargetType',
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.initSearchObj, 'projectTargetType', options.value)
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                {
                  columnIndex: 1,
                  this: this,
                  baseVue: 'BaseSelect',
                  placeholder: '推广科室',
                  layoutRight: true,
                  class: 'select-complex',
                  selectClass: 'select',
                  rightClass: 'right-btn',
                  multiple: true,
                  isShow({root}) {
                    return root.data.academicType !== 'academic-program'
                  },
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  clearable: true,
                  value: 'getRootItem(root,item.key)',
                  key: 'data.initSearchObj.spreadDepartment',
                  selectList: 'getRootItem(root,item.selectListKey)',
                  selectListKey: 'data.tagItemList.SPREAD_DEPARTMENT_GROUP',
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.initSearchObj, 'spreadDepartment', options.value)
                      setRootItem(root.data.initSearchObj.tagAndGroupIds[1], 'tagIds', options.value)
                      setRootItem(root.data.initSearchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    },
                    click: function(options, {root}) {
                      this.$router.push({name: 'ProductLabel', query: {activeTabName: root.data.SPREAD_DEPARTMENT_GROUP_ID}})
                    }
                  }
                }
              ]
            },
            // 学术项目table
            {
              baseVue: 'BaseTable',
              isMounted: function({root}) {
                return root.data.academicType === 'academic-program'
              },
              style: {
                marginTop: '10px'
              },
              methods: {
                selectionChange: options => {
                  console.log(options)
                }
              },
              tableData: 'getRootItem(root,item.tableDataKey)',
              tableDataKey: 'data.tableData',
              tableColumn: [
                {
                  label: '项目名称',
                  prop: 'projectName',
                  fixed: 'left'
                },
                {
                  label: '状态',
                  prop: 'projectStatusDesc',
                  align: 'center'
                },
                {
                  label: '项目时间',
                  prop: 'publishAt',
                  align: 'center'
                },
                {
                  label: '产品策略',
                  prop: 'strategyName',
                  align: 'center'
                },
                {
                  label: '操作',
                  prop: 'activate',
                  align: 'center',
                  width: '120px',
                  slot: {
                    column: [
                      {
                        baseVue: 'BaseButton',
                        type: 'text',
                        text: '查看',
                        methods: {
                          click: function(options) {
                            const {value: {row}} = options
                            this.$router.push({
                              name: 'ProjectStaegicDetail',
                              query: {
                                id: row.projectId
                              }
                            })
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            },
            // 活动table
            {
              baseVue: 'BaseTable',
              isMounted: function({root}) {
                return root.data.academicType === 'project'
              },
              style: {
                marginTop: '10px'
              },
              methods: {
                selectionChange: options => {
                  console.log(options)
                }
              },
              tableData: 'getRootItem(root,item.tableDataKey)',
              tableDataKey: 'data.tableData',
              tableColumn: [
                {
                  label: '活动名称',
                  prop: 'sceneTitle',
                  fixed: 'left'
                },
                {
                  label: '活动类型',
                  prop: 'sceneType',
                  align: 'center',
                  slot: {
                    column: [
                      {
                        baseVue: 'BaseSpan',
                        style: {
                          justifyContent: 'center'
                        },
                        text: function({item}) {
                          const obj = {
                            '1': '科会',
                            '4': item.row.questionnaireSubType === 1 ? '调研' : '病例',
                            '5': '直播',
                            '6': '会议',
                            '8': '拜访'
                          }
                          return obj[String(item.row.sceneType, item.row.questionnaireSubType)] || '-'
                        }
                      }
                    ]
                  }
                },
                {
                  label: '产品标签',
                  prop: 'projectTag',
                  align: 'center'
                },
                {
                  label: '推广科室标签',
                  prop: 'promotionDeptTags',
                  align: 'center'
                },
                {
                  label: '活动分享组织',
                  prop: 'createOrgName',
                  align: 'center'
                },
                {
                  label: '创建时间',
                  prop: 'createdAt',
                  align: 'center'
                },
                {
                  label: '操作',
                  prop: 'activate',
                  align: 'center',
                  slot: {
                    column: [
                      {
                        baseVue: 'BaseButton',
                        type: 'text',
                        text: '查看',
                        methods: {
                          click: function(options) {
                            const {value} = options
                            const obj = {
                              '1': 'meeting',
                              '4': 'questionnaire',
                              '5': 'live',
                              '6': 'webinar',
                              '8': 'visit'
                            }
                            if (value.row.sceneType === 4 && value.row.questionnaireSubType === 1) {
                              this.$router.push(`/sub-app/academic-saas/questionnaire/detail/${value.row.sceneId}`)
                            } else {
                              this.$router.push(`/academic/${obj[String(value.row.sceneType)]}/detail/${value.row.sceneId}?isCreator=${value.row.isCreator}&from=common`)
                            }
                          }
                        }
                      },
                      {
                        baseVue: 'BaseButton',
                        type: 'text',
                        style: function({item}) {
                          return {
                            color: item.row?.isCreator ? '#E8312F' : ''
                          }
                        },
                        text: function({item}) {
                          return item.row?.isCreator ? '取消分享' : '分配任务'
                        },
                        methods: {
                          click: function(options, {root, setRootItem}) {
                            console.log(options)
                            const {value} = options
                            setRootItem(root, 'data.visibleItem', value.row)
                            if (value.row.isCreator) {
                              setRootItem(root, 'data.isVisible', true)
                            } else {
                              const obj = {
                                '1': 'meeting',
                                '4': 'questionnaire',
                                '5': 'live',
                                '6': 'webinar',
                                '8': 'visit'
                              }
                              if (value.row.sceneType === 4 && value.row.questionnaireSubType === 1) {
                                this.$router.push({ name: 'CreatePureSurvey', query: {questionnaireId: value.row.sceneId} })
                              } else {
                                this.$router.push(`/academic/scene/${obj[String(value.row.sceneType)]}/rule/${value.row.sceneId}?disabledBack=${!value.row.canEdit}`)
                              }
                            }
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            },
            // 资料table
            {
              baseVue: 'BaseTable',
              isMounted: function({root}) {
                return root.data.academicType === 'material'
              },
              style: {
                marginTop: '10px'
              },
              methods: {
                selectionChange: options => {
                  console.log(options)
                }
              },
              tableData: 'getRootItem(root,item.tableDataKey)',
              tableDataKey: 'data.tableData',
              tableColumn: [
                {
                  label: '资料名称',
                  prop: 'resourceTitle',
                  fixed: 'left'
                },
                {
                  label: '类型',
                  prop: 'sceneType',
                  align: 'center',
                  slot: {
                    column: [
                      {
                        baseVue: 'BaseSpan',
                        style: {
                          justifyContent: 'center'
                        },
                        text: function({item}) {
                          const obj = {
                            '0': '视频',
                            '2': '文献'
                          }
                          return obj[String(item.row.resourceType)] || '-'
                        }
                      }
                    ]
                  }
                },
                {
                  label: '产品标签',
                  prop: 'projectTag',
                  align: 'center'
                },
                {
                  label: '推广科室标签',
                  prop: 'promotionDeptTags',
                  align: 'center'
                },
                {
                  label: '创建组织',
                  prop: 'createOrgName',
                  align: 'center'
                },
                {
                  label: '创建时间',
                  prop: 'createdAt',
                  align: 'center'
                },
                {
                  label: '引用分享次数',
                  prop: 'sharedCount',
                  align: 'center'
                },
                {
                  label: '操作',
                  prop: 'activate',
                  align: 'center',
                  slot: {
                    column: [
                      {
                        baseVue: 'BaseButton',
                        type: 'text',
                        text: '查看资料',
                        methods: {
                          click: function(options) {
                            const {value: {row}} = options
                            const obj = {
                              '0': this.onVideoViewer,
                              '2': this.onCheckKnowledge
                            }
                            obj[String(row.resourceType)] && obj[String(row.resourceType)](row)
                          }
                        }
                      },
                      {
                        baseVue: 'BaseButton',
                        isShow: function({item}) {
                          return item.row?.isCreator
                        },
                        type: 'text',
                        style: function({item}) {
                          return {
                            color: item.row?.isCreator ? '#E8312F' : ''
                          }
                        },
                        text: '取消分享',
                        methods: {
                          click: function(options, {root, setRootItem, runInitFun}) {
                            console.log(options)
                            const {value} = options
                            setRootItem(root, 'data.visibleItem', value.row)
                            if (value.row.isCreator) {
                              setRootItem(root, 'data.isVisible', true)
                            } else {
                              const params = {
                                resourceType: value.row.resourceType,
                                resourceId: value.row.resourceId
                              }
                              this.$axios.get(this.$API.addToMyLib, {params}).then(() => {
                                const {getTableDataList} = root.initFun
                                runInitFun(getTableDataList)
                              }, rea => this.$message.error(rea.message))
                            }
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            },
            // 分页器
            {
              baseVue: 'BasePagination',
              pageSize: 'getRootItem(root,item.pageSizeKey)',
              pageSizeKey: 'data.initSearchObj.pageSize',
              currentPage: 'getRootItem(root,item.currentPageKey)',
              currentPageKey: 'data.initSearchObj.pageNo',
              pageSizes: [10, 20, 50, 100],
              background: true,
              total: 'getRootItem(root, item.totalKey)',
              totalKey: 'data.total',
              layout: 'total, sizes, prev, pager, next',
              small: true,
              style: {
                marginTop: '10px'
              },
              methods: {
                currentChange: function(options, {root, setRootItem, runInitFun}) {
                  setRootItem(root.data.initSearchObj, 'pageNo', options.value)
                  const {getTableDataList} = root.initFun
                  runInitFun(getTableDataList)
                },
                sizeChange: function(options, {root, setRootItem, runInitFun}) {
                  setRootItem(root, 'data.initSearchObj.pageNo', 1)
                  setRootItem(root, 'data.initSearchObj.pageSize', options.value)
                  const {getTableDataList} = root.initFun
                  runInitFun(getTableDataList)
                }
              }
            },
            // 取消分享弹窗
            {
              id: this.$nanoid(),
              baseVue: 'BaseDialog',
              visible: 'getRootItem(root,item.visibleKey)',
              visibleKey: 'data.isVisible',
              title: '取消分享',
              width: '30%',
              content: [
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseSpan',
                  text: function({root}) {
                    const str = root.data.academicType === 'project' ? '活动' : '资料'
                    return `一经取消分享，该${str}将不再支持被分配任务，请确认是否取消分享该${str}？`
                  },
                  style: {
                    padding: '20px',
                    textAlign: 'center'
                  }
                }
              ],
              footer: [
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButton',
                  type: ' info',
                  plain: true,
                  text: '取消',
                  style: {
                    width: '92px'
                  },
                  methods: {
                    click: function(options, {setRootItem, root}) {
                      console.log(options)
                      setRootItem(root, 'data.isVisible', false)
                    }
                  }
                },
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButton',
                  type: 'primary',
                  text: '确认',
                  style: {
                    width: '92px'
                  },
                  methods: {
                    click: function(options, {setRootItem, root, runInitFun}) {
                      setRootItem(root, 'data.isVisible', false)
                      const reqItemObj = root.data.reqObj[root.data.academicType].cancelObj
                      const reqData = {}
                      const dataMaps = reqItemObj.dataMaps
                      Object.keys(reqItemObj.data).forEach(key => {
                        if (typeof reqItemObj.data[key] === 'function') {
                          reqData[key] = reqItemObj.data[key](root.data.visibleItem[dataMaps[key]])
                        } else {
                          reqData[key] = root.data.visibleItem[key]
                        }
                      })
                      reqData.isShareOrCancel = false
                      const configObj = {
                        'get': [{params: reqData}],
                        'post': [reqData]
                      }
                      this.$axios[reqItemObj.method](this.$API[reqItemObj.apiUrl], ...configObj[reqItemObj.method]).then(() => {
                        this.$message.success('取消分享成功')
                        const {getTableDataList} = root.initFun
                        runInitFun(getTableDataList)
                      }, rea => this.$message.error(rea.message))
                    }
                  }
                }
              ],
              methods: {
                handleClose: `function({item} = options, {setRootItem, root}) {
                  setRootItem(root, item.visibleKey, false)
                }`
              }
            }
          ]
        }
      ]

    }
  },
  computed: {
  },
  created() {
    this.schemeList[0].that = this
  },
  mounted() {
    this.getTagGroup()
  },
  activated() {
    this.handelEditSceneTagModalClose()
  },
  methods: {
    ...mapActions(['getTagGroup']),
    // 标签弹窗关闭后
    handelEditSceneTagModalClose() {
      runInitFunSingleton(this.schemeList[0], setRootItem).call(this, this.schemeList[0].init, {
        item: this.schemeList[0],
        root: this.schemeList[0],
        runInitFun: runInitFunSingleton(this.schemeList[0], setRootItem)
      })
    },
    // 预览视频
    onVideoViewer(row) {
      const {playUrl = []} = row
      if (!playUrl) {
        this.$message.error('当前视频没有播放地址')
        return
      }
      this.showVideoVewer = true
      this.currentSrc = {
        mp4: {
          hd: this.findUrl('.mp4', 'hd', playUrl),
          sd: this.findUrl('.mp4', 'sd', playUrl),
          ld: this.findUrl('.mp4', 'ld', playUrl)
        },
        hls: {
          hd: this.findUrl('.m3u8', 'hd', playUrl),
          sd: this.findUrl('.m3u8', 'sd', playUrl),
          ld: this.findUrl('.m3u8', 'ld', playUrl)
        }
      }
    },
    findUrl(type, clarity, arr) {
      let url = ''
      arr = arr || []
      arr.some(item => {
        if (item.indexOf(type) !== -1 && item.indexOf(clarity) !== -1) {
          url = item
          return true
        }
        return false
      })
      return url
    },
    // 查看文献
    onCheckKnowledge(row) {
      if (row.knowledgeType === 'WORD') {
        const routeUrl = this.$router.resolve({
          path: `/material/article/preview/${row.resourceId}`
        })
        window.open(routeUrl.href, '_blank')
      } else {
        window.open(row.pdfUrl, '_blank')
      }
    }
  }

}
</script>

<style lang="scss" scoped>
::v-deep .collapse-tags {
  .el-select {
    .el-select__tags {
      >span {
        display: flex;
        overflow: hidden;
        width: 100%;
        span:nth-child(1) {
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}
</style>

