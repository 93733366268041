<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
    <div class="add-aricle-box">
      <div class="left-box content-box">
        <div class="pdg-lr10 mgn-b20">
          <el-row type="flex" justify="space-between">
            <el-row type="flex">
              <div class="material-question-search-box">
                <el-input
                  v-model="searchData.searchKey"
                  placeholder="搜索内容标题/ID/创建者"
                  suffix-icon="el-icon-search"
                  @change="onSearchChange"
                />
              </div>
              <div class="pdg-lr10 select-item">
                <el-select
                  v-model="searchData.isQueryAll"
                  placeholder="素材来源"
                  @change="onSelectChange"
                >
                  <el-option
                    v-for="item in orgSource"
                    :key="item.isQueryAll"
                    :label="item.title"
                    :value="item.isQueryAll"
                  />
                </el-select>
              </div>
              <div class="pdg-lr10 select-item">
                <el-select
                  v-model="searchData.tagId"
                  style="width: 110px;"
                  placeholder="所有产品"
                  class="reset-item"
                  clearable
                  @change="onSelectChange"
                >
                  <el-option
                    v-for="product in productList"
                    :key="product.tagId"
                    :label="product.tagName"
                    :value="product.tagId"
                  />
                </el-select>
              </div>
              <div v-if="searchData.isQueryAll === false" class="pdg-lr10 select-item">
                <el-select
                  v-model="searchData.categoryId"
                  placeholder="所有分类"
                  clearable
                  @change="onSelectChange"
                >
                  <el-option
                    v-for="item in examClassifyList"
                    :key="item.categoryId"
                    :label="item.title"
                    :value="item.categoryId"
                  />
                </el-select>
              </div>
            </el-row>
            <div class="text-primary-link" @click="onCreate">新建文献</div>
          </el-row>
        </div>

        <div class="content-box">
          <el-table :data="sourceList" class="table-box" border>
            <el-table-column
              prop="knowledgeId"
              label="ID"
              width="100"
              show-overflow-tooltip
            />
            <el-table-column
              prop="title"
              label="文献标题"
              show-overflow-tooltip
            />
            <el-table-column
              prop="knowledgeType"
              label="文献类型"
              width="70"
            />
            <el-table-column
              prop="title"
              label="产品标签"
              width="70"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-for="(item, index) of scope.row.tagInfos"
                  :key="index"
                >{{ item.tagName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="70" center>
              <template slot-scope="scope">
                <span
                  v-if="isSelect(scope.row.knowledgeId)"
                  style="color: #c8c9ce;"
                >已选择</span>
                <div v-else class="cursor" @click="onAdd(scope.row)">
                  <i class="el-icon-circle-plus-outline opt-label" />
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="sorter-box"
            :current-page="searchData.pageNo"
            :page-size="searchData.pageSize"
            :total="total"
            :small="true"
            :pager-count="pagerCount"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="onCurrentChange"
          />
        </div>
      </div>
      <div class="right-box content-box">
        <el-row class="mgn-b10">
          <div class="col272e40 font-bold">当前选择文献：</div>
          <div class="col7784a1">请从左侧文献列表选择添加</div>
        </el-row>
        <div class="content-box check-box">
          <el-table :data="targetList" class="table-box" border>
            <el-table-column
              prop="knowledgeId"
              label="ID"
              show-overflow-tooltip
            />
            <el-table-column label="操作" center>
              <template slot-scope="scope">
                <div class="cursor" @click="onDel(scope.row.knowledgeId)">
                  <i class="el-icon-remove-outline opt-label" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- <el-pagination
      class="sorter-box"
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="total"
      :small="true"
      :pager-count="pagerCount"
      background
      hide-on-single-page
      layout="total, prev, pager, next, jumper"
      @current-change="onCurrentChange"
    /> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MaterialArticleChild',
  components: {},
  props: {
    selectList: {
      type: Array,
      default() {
        return []
      }
    },
    knowledgeType: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
      // -------------------- 搜索、页数 -----------------
      pagerCount: 5,
      small: true,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 5,
        type: 0,
        searchKey: '',
        categoryId: ''
      },
      // ---------------------- select -----------------
      examClassifyList: [],
      // --------------------- questionList ----------------------
      sourceList: [],
      targetList: [],
      orgSource: [
        { isQueryAll: true, title: '所有素材' },
        { isQueryAll: false, title: '我的素材' }
      ],
      productList: []
    }
  },
  computed: {
    isSelect() {
      return currentId => {
        const targetSelect = [...this.targetList].map(v => v.knowledgeId)
        return targetSelect.indexOf(currentId) > -1
      }
    }
  },
  watch: {
    selectList(val) {
      if (val) {
        this.targetList = val
      }
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    ...mapActions(['getArticleList', 'onGetCategoryList']),
    onClose() {
      this.$emit('onClose', false)
    },
    onCreate() {
      const routeUrl = this.$router.resolve('/material/knowledge/create')
      window.open(routeUrl.href, '_blank')
    },
    getValue() {
      return this.targetList
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 7,
        searchKey: '',
        categoryId: '',
        knowledgeType: this.knowledgeType || ''
      }
      this.sourceList = []
      this.targetList = this.selectList
      this.onGetArticleList()
      this.onGetExamCategoryList()
      this.getTagGroup().then(res => {
        const row = res.find(item => item.groupName.includes('产品'))
        this.getProdectList(row.id)
      })
    },
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup)
      if (data.length) {
        this.tagGroup = data
      }
      return data
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId
      }
      const { data } = await this.$axios.get(this.$API.tagList, { params })
      if (data.length) {
        this.productList = data
      }
    },
    onGetArticleList() {
      this.getArticleList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0
          this.sourceList = data || []
        }
      )
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetArticleList()
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetArticleList()
    },
    onGetExamCategoryList() {
      this.onGetCategoryList({ categoryType: 'knowledge' }).then(res => {
        this.examClassifyList = res.data || []
      })
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onGetArticleList()
    },
    onAdd(item) {
      this.targetList = [...this.targetList, item]
    },
    onDel(currentId) {
      this.targetList = [...this.targetList].filter(
        v => v.knowledgeId !== currentId
      )
    }
  }
}
</script>

<style lang='scss' scoped>
.add-aricle-box {
  // flex: 1;
  display: flex;
  // overflow: hidden;
}
.table-box {
  width: 100%;
  max-height: 320px;
  overflow-y: scroll;
}
.opt-label {
  color: #3d61e3;
  font-size: 20px;
}
.item-box {
  // width: 490px;
  // height: 640px;
  @media screen and (max-width: 768px) {
    height: 300px;
    overflow: scroll;
  }
}
.left-box {
  width: 90%;
  margin-right: 10px;
}
.content-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  .el-table {
    height: 100%;
    overflow-y: scroll;
  }
}
.check-box {
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.check-box::-webkit-scrollbar {
  width: 0 !important;
}
.sorter-box {
  padding: 10px;
  text-align: right;
  background: #fff;
}
.select-item {
  width: 120px;
}
</style>
