<template>
  <div>
    <el-table
      ref="multipleTable"
      :data="list"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="isShowSelect"
        type="selection"
        width="55"
      />
      <el-table-column
        fixed
        prop="title"
        :label="sceneTitle"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createdAt"
        label="创建时间"
        align="center"
      />
      <el-table-column prop="orgName" align="center" label="所属组织">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot="header" slot-scope="scoped">
          <el-cascader
            :value="[targetOrgId]"
            :options="options"
            :props="defaultProps"
            :show-all-levels="false"
            placeholder="所属组织"
            clearable
            @change="handleChange"
          />
        </template>
      </el-table-column>
      <el-table-column prop="managerName" label="组织管理员" align="center" />
      <el-table-column align="center" label="完成数/总任务数">
        <template slot="header">
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="我及我以下组织分配的任务数汇总"
          >
            <span slot="reference">完成数/总任务数 <i class="el-icon-question" /></span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <span>{{ (scope.row.finishedCount ? scope.row.finishedCount : 0) + '/' + (scope.row.allCount || 0) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right">
        <template slot-scope="scope">
          <span class="text-primary-link" style="margin-right: 15px" @click="onJumpDetail(scope.row.sceneId)">查看详情</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'SceneDownChildTable',
  filters: {
    inviteFilter(v) {
      return `${v.completeCount}/${v.totalCount}`
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isShowSelect: {
      type: Boolean,
      default: false
    },
    sceneType: {
      type: String,
      default: 'meeting'
    },
    targetOrgId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: [],
      orgName: '',
      defaultProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true,
        emitPath: false
      }
    }
  },
  computed: {
    sceneTitle() {
      const nameData = {
        meeting: '科会名称',
        questionnaire: '调研名称',
        live: '直播名称',
        webinar: '会议名称',
        academicVisit: '拜访名称'
      }
      return nameData[this.sceneType]
    },
    sceneDesc() {
      return this.sceneType === 'questionnaire' ? '题目数' : '素材数'
    }
  },
  watch: {
    isShowSelect(val) {
      if (val) this.toggleSelection()
    }
  },
  mounted() {
    this.getOrgList().then(
      res => {
        this.options = res.child
      }
    )
  },
  methods: {
    ...mapActions(['getOrgList']),
    onJumpDetail(sceneId) {
      this.$router.push(`/academic/${this.sceneType}/detail/${sceneId}`)
    },
    handleChange(value) {
      this.$emit('update:targetOrgId', value)
      this.$emit('on-change', value)
    },
    handleSelectionChange(val) {
      this.$emit('onSelect', val)
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-wrapper {
  flex-wrap: wrap;
}
.page {
  padding: 15px;
}
</style>
