<template>
  <div>
    <page-main back title>
      <div v-if="form.auditStatus === 2" class="approve-info">
        <span>该申请已驳回：{{ form.rejectReason }}</span>
        <span>操作时间：{{ form.reviewAt }}</span>
      </div>
      <live-form
        ref="liveForm"
        v-model="form"
        v-loading="loading"
        :is-approve="true"
        :default-view-min="defaultViewMin"
      />
    </page-main>
    <fixed-action-bar>
      <template v-if="!isDisabled">
        <el-button
          class="w100"
          plain
          type="info"
          @click="visible = true"
        >
          驳回
        </el-button>
        <el-button
          class="w100"
          type="primary"
          plain
          :loading="loading"
          @click="onSaveData(true)"
        >
          通过并分配
        </el-button>
        <el-button
          type="primary"
          class="w100"
          :loading="loading"
          @click="dialogVisible = true"
        >
          通过
        </el-button>
      </template>
      <template v-else>
        <el-button class="w100" @click="$router.back()">返回</el-button>
      </template>
    </fixed-action-bar>

    <!-- dialog 区 -->
    <el-dialog title="直播申请" :visible.sync="dialogVisible" width="450px">
      <div class="content">
        <span>确定通过该直播申请？</span>
        <p>通过后活动将在活动管理中呈现。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="w100" @click="dialogVisible = false">再想想</el-button>
        <el-button
          class="w100"
          type="primary"
          :loading="isSubmitLoading"
          @click="onSaveData(false)"
        >确 认</el-button>
      </span>
    </el-dialog>

    <el-dialog title="填写驳回理由" :visible.sync="visible" width="450px">
      <div class="textarea-content">
        <el-input
          v-model="form.reason"
          type="textarea"
          :rows="3"
          placeholder="请填写驳回理由"
          maxlength="30"
          show-word-limit
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="w100" @click="visible = false">取消</el-button>
        <el-button class="w100" type="primary" @click="turnDown">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import LiveForm from './components/liveForm.vue'
export default {
  name: 'LiveApproveForm',
  components: {
    LiveForm
  },
  data() {
    return {
      dialogVisible: false,
      visible: false,
      loading: false,
      isSubmitLoading: false,
      form: {
        projectId: '',
        strategyId: ''
      },
      defaultViewMin: 10
    }
  },
  computed: {
    isDisabled() {
      return this.form.auditStatus === 2 || this.form.auditStatus === -1 || this.form.auditStatus === 1
    }
  },
  mounted() {
    this.$store.dispatch('getCertifyRule', 5).then(() => {
      this.defaultViewMin = this.$store.state.live.liveDefaultReviewSec
      this.getDetails()
    })
  },
  methods: {
    // 获取详情
    async getDetails() {
      const params = {
        liveAuditRecordId: this.$route.params.id
      }
      try {
        this.loading = true
        const {data} = await this.$axios.get(this.$API.getLiveAuditDetail, {params})
        data.occupationImg = data.waitingImg
        data.mustViewMin = Math.max(data.mustViewMin, this.defaultViewMin)
        data.examType = data.examType || 0
        data.examList = data.examList || []
        if (data.auditStatus === 1) {
          this.$nextTick(() => {
            this.$refs.liveForm.$refs.editor.ue.disable()
          })
        }
        this.form = Object.assign(this.$refs.liveForm?.defaultForm, data)
        this.form.strategyDisabled = !!this.form.strategyId
        this.form.speakers = data.speakers.map(item => {
          delete item.avatar
          return {
            ...item,
            avatar: item.avatarVO?.imageName,
            avatarUrl: item.avatarVO?.middle
          }
        })
        this.loading = false
        this.$refs.liveForm?.initTag(data.id, true)
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    // 驳回
    async turnDown() {
      if (!this.form.reason) {
        this.$message.error('请填写驳回理由')
        return false
      }
      const data = {
        liveAuditRecordIds: [this.$route.params.id],
        reason: this.form.reason
      }
      try {
        await this.$axios.post(this.$API.rejectLiveCreate, data)
        this.$message.success('该提交已驳回')
        this.visible = true
        this.$EventBus.$emit('getList')
        this.$router.back()
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    onSubmit(params, flag) {
      this.$axios.post(this.$API.successLiveCreate, {...params, liveAuditRecordId: this.$route.params.id, isSuccessAndAssign: flag}).then(() => {
        this.isSubmitLoading = false
        this.$message.success('审核成功')
        this.$EventBus.$emit('getList')
        if (flag) {
          this.$refs.liveForm?.onNext()
        } else {
          this.$router.back()
        }
      }, rea => {
        this.isSubmitLoading = false
        this.$message.error(rea.message)
      })
    },
    onSaveData(flag) {
      this.dialogVisible = false
      this.$refs.liveForm.onSaveData(flag).then(async params => {
        try {
          this.isSubmitLoading = true
          await this.$axios.post(this.$API.checkLiveSpeaksRule, {
            memberTagInfo: params.memberTagInfo[0],
            speakers: params.speakers,
            startDate: params.startAt,
            isCreat: !this.$route.params.id || this.$route.query.source === 'approver'
          }).then(res => {
            if (res.data.length > 0) {
              this.$confirm(`播客${res.data}近期直播场次过多，可能会造成直播质量较差，审核不通过，建议修改直播时间`, '直播审批失败', {
                confirmButtonText: '确定',
                type: 'warning',
                showCancelButton: false,
                center: true
              }).then(() => {
                this.isSubmitLoading = false
              }).catch(() => {
                this.isSubmitLoading = false
              })
            } else {
              this.onSubmit(params, flag)
            }
          })
        } catch (error) {
          this.isSubmitLoading = false
          this.$message.error(error.message)
        }
      })
    },
    onCancelPop() {
      this.$refs.liveForm.onCancelPop()
    }
  }
}
</script>

<style lang="scss" scoped>
.approve-info {
  text-align: center;
  background-color: rgba(252, 228, 228, 1);
  color: rgba(232, 49, 47, 1);
  height: 30px;
  line-height: 30px;
  margin-top: -$g-main-wrap-padding;
  margin-left: -$g-main-wrap-padding;
  margin-right: -$g-main-wrap-padding;
  margin-bottom: 30px;
  >span:nth-child(2) {
    margin-left: 40px;
  }
}
.btn {
  width: 100px;
}
.content {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 45px;
  line-height: 22px;
  color: #0f0f0f;
}
.textarea-content {
  margin: 14px 15px 29px 15px;
}
</style>
