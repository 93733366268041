var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{ref:"multipleTable",staticClass:"customize-el-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.list},on:{"selection-change":_vm.handleSelectionChange}},[(_vm.isShowSelect)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_c('el-table-column',{attrs:{"fixed":"","prop":"title","label":"作品名称","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpera(row, 'play')}}},[_vm._v(" "+_vm._s(row.title)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"challengeTitle","label":"活动名称","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"duration","label":"视频时长","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(Math.floor(row.duration / 60))+":"+_vm._s(row.duration % 60)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"author","label":"作者","align":"center"}}),_c('el-table-column',{attrs:{"prop":"mustViewMin","label":"审核要求","align":"center","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"rule-detail"},[(row.isViewNumAndTimeOk)?_c('i',{staticClass:"el-icon-success"}):_c('i',{staticClass:"el-icon-warning"}),_vm._v(" 观看满"+_vm._s(row.mustViewMin || 0)+"分钟人数大于5人 ")])]}}])}),_c('el-table-column',{attrs:{"width":"180","prop":"podcastReviewStatus","label":"内容审核状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.podcastReviewStatus === -2 || row.podcastReviewStatus === 4)?_c('el-popover',{attrs:{"placement":"top-start","title":"原因：","width":200,"trigger":"hover","content":row.failReason},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('span',{style:({ color: _vm.getColor(row.podcastReviewStatus) })},[_vm._v(_vm._s(row.podcastReviewStatusDesc))])]},proxy:true}],null,true)}):_c('span',{style:({ color: _vm.getColor(row.podcastReviewStatus) })},[_vm._v(_vm._s(row.podcastReviewStatusDesc))])]}}])}),_c('el-table-column',{attrs:{"prop":"managerName","sort-by":"totalCount","sortable":"","width":"180","label":"已完成/所有任务","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.completeCount)+"/"+_vm._s(row.totalCount))])]}}])}),(_vm.academicSourceType === 'down')?_c('el-table-column',{attrs:{"label":"创建组织","prop":"createOrgName","show-overflow-tooltip":"","align":"center"}}):_vm._e(),_c('el-table-column',{attrs:{"align":"center","label":"操作","fixed":"right","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-row',[((row.podcastReviewStatus === 2 ||row.podcastReviewStatus === 3 || row.podcastReviewStatus === 5) && !_vm.isMobile)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpera(row, 'edit')}}},[_vm._v(" 编辑 ")]):_vm._e(),(row.podcastReviewStatus === 2 && !row.isPreReimbursement && row.isViewNumAndTimeOk)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpera(row, 'buy')}}},[_vm._v(" 内容合适 ")]):_vm._e(),(row.podcastReviewStatus === 2 && !row.isPreReimbursement && row.isViewNumAndTimeOk)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpera(row, 'reject')}}},[_vm._v(" 内容不合适 ")]):_vm._e(),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpera(row, 'assign')}}},[_vm._v(" 分配 ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpera(row, 'withdraw')}}},[_vm._v(" 撤回 ")]),(!_vm.isMobile)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpera(row, 'url')}}},[_vm._v(" 观看链接 ")]):_vm._e()],1)]}}])})],1),_c('LivePlayBack',{attrs:{"live-id":_vm.currentRow.liveId,"live-status":_vm.currentRow.podcastStatus},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('VideoPlayModal',{attrs:{"visible":_vm.playVideoDialog,"title":_vm.currentRow.title,"video-url":_vm.currentRow.videoUrl},on:{"onClose":_vm.onCloseModal}}),_c('RecallSceneCodeModal',{attrs:{"visible":_vm.withdrawDialog,"scene-id":_vm.currentRow.liveId,"scene-type":_vm.sceneType},on:{"onClose":_vm.onCloseModal}}),_c('QRCodeModal',{attrs:{"visible":_vm.showQRCodeDialog,"url":_vm.currentRow.doctorClientUrl,"qr-code":_vm.currentRow.doctorClientQRCode},on:{"onClose":_vm.onCloseModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }