<template>
  <el-dialog
    class="speaker-invite-modal customize-el-dialog"
    title="添加播客"
    width="80"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-row
      class="lay-box"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <el-input
        v-model="searchText"
        clearable
        placeholder="请输入医生姓名"
        class="search-input"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          type="primary"
          round
          @click="onSearch"
        />
      </el-input>
    </el-row>
    <div v-loading="loading" class="lay-box">
      <el-table
        :data="speakerList"
        stripe
        :height="270"
        row-class-name="cursor"
        @row-click="onSelect"
      >
        <el-table-column fixed width="50" :disabled="true">
          <template slot-scope="scope">
            <div class="checbox-scope" :class="{ active: isSelect(scope.row.id) }">
              <i v-if="isSelect(scope.row.id)" class="el-icon-success" />
            </div>
            <!-- <el-checkbox :value='isSelect(scope.row.id)' :label="isSelect(scope.row.id)"></el-checkbox> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          fixed
          align="center"
          show-overflow-tooltip
        />
        <el-table-column prop="selectedJobTitleId" align="center" label="所属职称">
          <template slot-scope="scope">
            <span>{{ scope.row.titleName || '/' }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="hospital"
          align="center"
          show-overflow-tooltip
          label="医院"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.hospital || '/' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="所在科室">
          <template slot-scope="scope">
            <span>{{ scope.row.department || '/' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="representItemList"
          show-overflow-tooltip
          align="center"
          label="服务代表"
        >
          <template slot-scope="{row}">
            <span v-for="(v, index) in row.representItemList" :key="v.id">
              {{ v.name }}{{ index === row.representItemList.length - 1? '' : '、' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.speakerSignStatus | statusFilter }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" class="t-p">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="pageNo"
          :total="totalCount"
          @current-change="onChange"
        />
      </el-row>
    </div>
    <template slot="footer">
      <el-row type="flex" justify="center">
        <el-button
          type="primary"
          plain
          @click="onClose"
        >
          取消
        </el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: 'AddSpeakerModal',
  filters: {
    statusFilter(status) {
      switch (status) {
        case 1:
          return '已签约'
        default:
          return '已解约'
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 1
    },
    selected: {
      type: Array,
      default() {
        return []
      }
    },
    disabledList: {
      type: Array,
      default() {
        return []
      }
    },
    projectId: {
      type: String,
      default: ''
    },
    sceneType: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedList: [], // 选择的医生列表
      selectedMap: {},
      searchText: '',
      pageNo: 1,
      pageSize: 20,
      totalCount: 0,
      loading: false,
      speakerList: []
    }
  },
  watch: {
    visible(show) {
      if (show) {
        this.init()
        this.selectedList = this.selected
      }
    }
  },
  mounted() {},
  methods: {
    onSearch() {
      this.pageNo = 1
      this.init()
    },
    onClose() {
      this.$emit('onClose')
    },
    onSubmit() {
      this.$emit('onSubmit', this.selectedList)
    },
    onChange(pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    isSelect(currentId) {
      return [...this.selectedList].map(v => v.id).indexOf(currentId) > -1
    },
    onSelect(current) {
      if (this.disabledList.includes(current.id)) return this.$message.error('当前播客不能编辑')
      if (this.isSelect(current.id)) {
        this.selectedList = [...this.selectedList].filter(v => v.id !== current.id)
      } else {
        if (this.max === 1) {
          this.selectedList = [current]
          return
        }
        if (this.selectedList.length >= this.max) {
          return this.$message.error(`最多选择${this.max}个播客`)
        }
        this.selectedList = [...this.selectedList, current]
      }
    },
    init() {
      this.loading = true
      // let req = {
      //   pageNo: this.pageNo,
      //   titleName: this.selectedJobTitle,
      //   querySpeaker:true,
      //   searchKey:this.searchText,
      //   speakerStatus:this.selectedSpeakerStatus,
      //   departmentId: this.selectedDepartmentId
      // }
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        querySpeaker: true,
        doctorName: this.searchText,
        projectId: this.projectId,
        speakerStatus: '',
        certifyStatus: '',
        status: 1,
        sceneType: this.sceneType
      }
      this.$axios.get(this.$API.getSpeakerList, {params}).then(
        res => {
          this.loading = false
          this.speakerList = res.data
          this.totalCount = res.count
        }).catch(error => {
        this.loading = false
        this.speakerList = []
        this.$msg.error(error.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.speaker-invite-modal ::v-deep {
  .el-dialog {
    width: 70%;
  }
  .checbox-scope {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #3d61e3;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 20px;
    .el-icon-success {
      color: #3d61e3;
      border-radius: 50%;
    }
    &.active {
      height: 22px;
      width: 22px;
      font-size: 22px;
      border-color: transparent;
    }
  }
}
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.t-p {
  padding-top: 15px;
}
.l-m {
  margin-left: 20px;
}
.picker-rd {
  width: 100%;
}
::v-deep .search-input {
  width: 280px;
  .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
</style>
